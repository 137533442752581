<template>
    <div style="margin: 10px">

        



        <!-- Tabla 1 -->
    <div class="row">
        <div class="col-lg-6">
          <table class="table table-bordered">
            <thead>
              <th>Predicciones recientes relacionadas</th>
            </thead>
            <tr>
              <td></td>
            </tr>
            <tr>
              <td></td>
            </tr>
            <tr>
              <td></td>
            </tr>
          </table>
        </div>
  
        <!-- Tabla 2 -->
        <div class="col-lg-6">
          <table class="table table-bordered">
            <thead>
              <th>Historial de predicciones</th>
            </thead>
            <tr>
              <td></td>
            </tr>
            <tr>
              <td></td>
            </tr>
            <tr>
              <td></td>
            </tr>
          </table>
        </div>
      </div>

      <form>
        <!-- Otros campos omitidos por brevedad -->
  
        <div class="row">
          <!-- Tipo de Impugnación -->
          <div class="mb-3 col-lg-4 col-xs-12">
            <label for="tipoImpugnacion" class="form-label" aria-label="Tipo de Impugnación *">
              Tipo de Impugnación *
            </label>
            <v-select
              :options="tiposImpugnacion"
              id="tipoImpugnacion"
              v-model="form.tipoImpugnacion"
              label="texto"
              placeholder="Selecciona"
              aria-label="Tipo de Impugnación"
            >
              <template #search="{ attributes, events }">
                <input
                  class="vs__search"
                  :required="!form.tipoImpugnacion"
                  v-bind="attributes"
                  v-on="events"
                />
              </template>
              <slot name="no-options">No se encontraron coincidencias.</slot>
            </v-select>
          </div>
  
          <!-- Ponente -->
          <div class="mb-3 col-lg-4 col-xs-12">
            <label for="ponente" class="form-label" aria-label="Ponente">
              Ponente *
            </label>
            <v-select
              :options="ponentes"
              id="ponente"
              v-model="form.ponente"
              label="texto"
              placeholder="Selecciona"
              aria-label="Ponente"
            >
              <template #search="{ attributes, events }">
                <input
                  class="vs__search"
                  :required="!form.modalidadesViolencia"
                  v-bind="attributes"
                  v-on="events"
                />
              </template>
              <slot name="no-options">No se encontraron coincidencias.</slot>
            </v-select>
          </div>
  
          <!-- Fecha -->
          <div class="mb-3 col-lg-4 col-xs-12">
            <label for="fecha" class="form-label" aria-label="Fecha">
              Fecha (Preferente de turno)
            </label>
            <input
              type="date"
              id="fecha"
              class="form-control"
              v-model.trim="form.fecha"
              aria-label="Fecha"
            />
          </div>
        </div>
  
        <!-- Modalidades de Violencia Política -->
        <div class="mb-3">
          <label for="modalidadesActos" class="form-label" aria-label="Modalidades de Actos*">
            Modalidades de Actos *
          </label>
          <v-select
            :options="modalidadesActos"
            id="modalidadesActos"
            v-model="form.modalidadesActos"
            label="texto"
            placeholder="Selecciona"
            aria-label="Modalidades de Actos"
          >
            <template #search="{ attributes, events }">
              <input
                class="vs__search"
                :required="!form.modalidadesActos"
                v-bind="attributes"
                v-on="events"
              />
            </template>
            <slot name="no-options">No se encontraron coincidencias.</slot>
          </v-select>
        </div>
  
        <!-- Resto de los campos omitidos por brevedad -->
      </form>
    </div>
  </template>

  <script>
  
  import "vue-select/dist/vue-select.css";
  import {
    //required,
  } from "vuelidate/lib/validators";
  import { mapState, mapActions, mapMutations } from "vuex";
  
  export default {
    props: ["clickedNext", "currentStep"],
    name: "GeneralForm",
    mounted() {
      // this.getStates();
      /* console.log("mounted", this.$v.$invalid); */
      this.setMunicipios([]);
      if (!this.$v.$invalid) {
        this.$emit("can-continue", { value: true });
      } else {
        // this.verTerminos();
        this.$emit("can-continue", { value: false });
      }
    },
    activated: function () {
      /* console.log("activate"); */
      if (!this.$v.form.$invalid) {
        // console.log("activate can");
        this.$emit("can-continue", { value: true });
      } else {
        // console.log("activate cant");
        this.$emit("can-continue", { value: false });
      }
    },
    deactivated: function () {
      // console.log("deactivated");
    },
    data() {
      return {
        form: {
          tipoImpugnacion: "",
          modalidadesActos: "",
          ponente:"",
          fecha:"",
        },
        tiposImpugnacion: ["JRC",
            "REP",
            "RAP",
            "JE",
            "PES",
            "PSC"
        ],
        modalidadesActos: [
            "Eventos de Campaña",
            "Difusión en Redes Sociales",
            "Publicidad en Radio/TV",
            "Uso Indebido de Recursos Públicos",
            "Difusión en Redes Sociales y Plataformas Online",
            "Publicidad Exterior",
            "Equipamiento Urbano",
            "Notas Periodísticas",
            "Entrevistas en Medios",
            "Publicidad Personalizada",
            "Violación a Principio de Equidad",
            "Violación a Principio de Imparcialidad",
            "Publicidad Audiovisual"
            ],
        ponentes: [
            "Janine M. Ótalora Malassis",
            "José Luis Vargas Valdez",
            "Felipe de la Mata Pizaña",
            "Indalfer Infante Gonzales",
            "Mónica Aralí Soto Fregoso",
            "Felipe Alfredo Fuentes Barrera",
            "Roberto Eduardo Sigala Aguilar",
            "Reyes Rodríguez Mondragón",
            "Gabriela Villafuerte Coello",
            "Rubén Jesús Lara Patrón",
            "Luis Espíndola Morales",
            "José Antonio Hernández Huesca"
          ]
      };
    },
    
    validations() {
      return {
        form: {
          fecha: {
            
          },
          tipoImpugnacion: {
            
          },
          ponente: {
            
          },
          modalidadesActos: {
           
          },
        },
      };
    },

    
    computed: {
      ...mapState("generals", [
        "organosJurisdiccionales",
        "cargosEleccionPopular",
        "tipoDocData",
        "generosData",
        "ciudades",
        "estados",
      ]),
    },
    methods: {
      ...mapMutations("contenido", ["setFormatoDatos"]),
      ...mapMutations("generals", ["setMunicipios",]),
      ...mapActions({
        getStates: "generals/getStates",
        getMunicipios: "generals/getMunicipios",

        submitForm() {
          this.$v.form.$touch(); // Asegura que se realice la validación
          if (!this.$v.form.$invalid) {
            // Si el formulario es válido, imprime los datos en la consola
            console.log("Datos del formulario:", this.form);
          }
        },
      }),
    },
    watch: {
      $v: {
        handler: function (val) {
          // console.log("es invalido cambio.... ", val.$invalid);

          console.log("Datos del formulario:", this.form);
          if (!val.$invalid) {
            this.$emit("can-continue", { value: true });
            this.setFormatoDatos(this.form);
          } else {
            this.$emit("can-continue", { value: false });
          }
        },
        deep: true,
      },
      clickedNext(val) {
        if (val === true) {
          this.$v.form.$touch();
          this.submitForm();
          // this.setFormatoDatos(this.form)
        }
      },
      
    },
  };
  </script>
  <style>
  .form-control {
    height: calc(1.5em + 0.75rem + 2px);
  }
  .vs__dropdown-toggle {
    border: 1px solid #eee;
    height: calc(1.5em + 0.75rem + 2px);
  }
  .v-select {
    height: calc(1.5em + 0.75rem + 2px);
    box-shadow: 0 1px 5px rgb(0 0 0 / 10%);
  }
  .error {
    border: 1px solid #dc3545;
    padding-right: calc(1.5em + 0.75rem);
    background-repeat: no-repeat;
    background-position: right calc(0.375em + 0.1875rem) center;
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
  }

  th {
    background-color: #1d4073;
    color: white; /* Texto blanco para contrastar con el fondo */
  }

  .table-bordered {
    border: 1px solid black;
  }
  </style>